import App from "@/components/App";
import Head from "next/head";
import { Owner } from "@/models/owner";
import {
  ownerDescription,
  ownerDomain,
  ownerFavicon,
  ownerImage,
  ownerTitle,
} from "@/lib/owner";
import getOwnerStaticPaths from "@/db/static/getOwnerStaticPaths";
import getOwnerStaticProps from "@/db/static/getOwnerStaticProps";
import Library from "@/components/Library";

type Props = {
  owner?: Owner;
};
const LibraryPage = ({ owner }: Props) => {
  return (
    <>
      <App owner={owner} topBar={"app"} sideBar={"app"}>
        <Library />
      </App>
      {!!owner && (
        <Head>
          <title>{ownerTitle(owner)}</title>
          <meta name="description" content={ownerDescription(owner)} />
          <meta name="image" content={ownerImage(owner)} />
          <meta name="url" content={ownerDomain(owner)} />
          <meta name="og:title" content={ownerTitle(owner)} />
          <meta name="og:description" content={ownerDescription(owner)} />
          <meta name="og:image" content={ownerImage(owner)} />
          <meta name="og:url" content={ownerDomain(owner)} />
          {!!ownerFavicon(owner) && (
            <link rel="icon" href={ownerFavicon(owner)} />
          )}
        </Head>
      )}
    </>
  );
};
export default LibraryPage;

export const getStaticPaths = getOwnerStaticPaths;
export const getStaticProps = getOwnerStaticProps;
