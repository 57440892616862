import { MAX_WIDTH, MOBILE_WIDTH } from "@/lib/constants";
import { colors } from "@/lib/styles";
import { Folder } from "@/models/folder";
import { Program } from "@/models/program";
import styled from "styled-components";
import ProgramPreview from "./ProgramPreview";
import FolderPreview from "./FolderPreview";

const Contents = styled.div`
  width: 100%;
  /* flex: 1; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 10px 10px 10px;
  max-width: ${MAX_WIDTH}px;
`;

const LoadingItem = styled.div`
  flex: 1;
  height: 400px;
  margin: 10px;
  flex-basis: 40%;
  border-radius: 10px;
  background-color: ${colors.nearWhite};
  @media screen and (max-width: ${MAX_WIDTH}px) {
    max-width: 500px;
  }
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    margin: 0 20px;
    width: calc(100% - 40px);
  }
`;

type Props = {
  loading?: boolean;
  folders?: Folder[];
  programs?: Program[];
};

const Items: React.FC<Props> = ({ folders, programs, loading }) => {
  // sort folders & programs by position property and map to preview components
  const sortableFolders =
    folders?.map((folder) => ({
      type: "FOLDER",
      position: folder.position,
      folder,
    })) || [];
  const sortablePrograms =
    programs?.map((program) => ({
      type: "PROGRAM",
      position: program.position,
      program,
    })) || [];
  const sortableItems = [...sortableFolders, ...sortablePrograms];
  const sortedItems =
    sortableItems.sort((a, b) => a.position - b.position) || [];

  return (
    <Contents>
      {!loading &&
        sortedItems.map((item) => {
          switch (item.type) {
            case "FOLDER":
              const folder = (item as any).folder as Folder;
              return <FolderPreview key={folder.id} folder={folder} />;
            case "PROGRAM":
              const program = (item as any).program as Program;
              return <ProgramPreview key={program.id} program={program} />;
          }
        })}
      {loading && (
        <>
          <LoadingItem />
          <LoadingItem />
          <LoadingItem />
          <LoadingItem />
        </>
      )}
    </Contents>
  );
};

export default Items;
