import styled from "styled-components";
import Link from "next/link";
import { colors } from "@/lib/styles";
import { fadeIn } from "@/lib/animations";
import { Program } from "@/models/program";
import { ownerPath } from "@/lib/path";
import { useContext } from "react";
import { OwnerContext } from "../App";
import Image from "next/image";
import { MOBILE_WIDTH, TABLET_WIDTH } from "@/lib/constants";
import { Owner } from "@/models/owner";
import { rgba } from "polished";
import useOwnerColor from "@/hooks/useOwnerColor";
import TagComponent from "../universal/Tags/Tag";
import { useQuery } from "react-query";
import { Tag } from "@/models/tag";
import getTags from "@/db/tag/getTags";
import { find } from "lodash";
import BuildIcon from "../icons/BuildIcon";
import useAccess from "@/hooks/useAccess";
import { Session } from "@/models/session";
import useUid from "@/hooks/useUid";
import getSession from "@/db/session/getSession";
import useGroup from "@/hooks/useGroup";
import CheckIcon from "../icons/CheckIcon";
import useAdmin from "@/hooks/useAdmin";
import useGroupAdmin from "@/hooks/useGroupAdmin";

const Wrapper = styled.a<{ active: boolean }>`
  margin: 10px;
  border: 1px solid ${colors.lightGray};
  display: flex;
  flex-direction: column;
  max-width: calc(33.333% - 20px);
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  flex-basis: 33.333%;
  flex-shrink: 0;
  border-radius: 10px;
  text-decoration: none;
  padding-bottom: 10px;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  ${fadeIn}
  &:hover {
    ${(props) =>
      props.active
        ? `
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.08) 0 1px 5px 0;
    `
        : ""}
  }
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    max-width: calc(50% - 20px);
    flex-basis: 50%;
  }
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    max-width: calc(100% - 20px);
    flex-basis: 100%;
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
`;

const Title = styled.h3`
  font-size: 21px;
  font-weight: 500;
  color: ${colors.nearBlack};
  flex: 1;
  padding: 10px 0 5px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 21px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${colors.darkGray};
  width: 100%;
  padding: 0 20px 10px;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px 20px 10px;
  margin-top: auto;
`;

const CoverImage = styled.div<{ color: string; empty?: boolean }>`
  width: 100%;
  height: 180px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${(props) =>
    props.empty ? rgba(props.color, 0.1) : colors.nearWhite};
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    height: 150px;
  }
`;

const ProgressLabel = styled.label`
  font-size: 16px;
  color: ${colors.darkGray};
  font-weight: 500;
  margin-left: auto;
`;

const TimeEstimate = styled.span`
  font-size: 16px;
  color: ${colors.darkGray};
  font-weight: 500;
  margin-left: auto;
`;

type Props = {
  program: Program;
  owner?: Owner;
  style?: object;
};
export const ProgramPreview: React.FC<Props> = ({
  program,
  style,
  ...otherProps
}) => {
  const access = useAccess(program);
  const ownerCtx = useContext(OwnerContext);
  const ownerColor = useOwnerColor();
  const uid = useUid();
  const owner = ownerCtx || otherProps.owner;
  const group = useGroup();
  const admin = useAdmin();
  const groupAdmin = useGroupAdmin();
  const anyAdmin = Boolean(admin || groupAdmin);

  const tagsQuery = useQuery<Tag[]>(["tags", owner.id], getTags(owner));
  const sessionQuery = useQuery<Session>(
    ["session", program.id, uid],
    uid && program.id
      ? getSession({ userId: uid, programId: program?.id })
      : null,
    {
      refetchOnWindowFocus: false,
    }
  );

  const session = sessionQuery.data;
  const sessionProgress = session
    ? session?.steps.filter((s) => !!s.completedTime).length /
      program?.steps.length
    : 0;

  const link =
    session && !session.completedTime && !admin && !groupAdmin
      ? ownerPath(owner, `program/${program.id}`, { session: session.id })
      : ownerPath(owner, [...program.slugPaths, program.slug].join("/"));

  let bottomRightEl: React.ReactNode = null;
  // if not an admin, and you finished the program, check mark
  if (!anyAdmin && !!session?.completedTime)
    bottomRightEl = (
      <CheckIcon
        color={colors.nearBlack}
        height={16}
        style={{ transform: "translateY(2px)", marginLeft: "auto" }}
      />
    );
  // if not an admin, and you started the program, progress percentage
  if (!anyAdmin && !!session && !session?.completedTime)
    bottomRightEl = (
      <ProgressLabel color={ownerColor}>
        {Math.round(sessionProgress * 100)}%
      </ProgressLabel>
    );
  // if not an admin, and you haven't started the program, estimated length
  if (!anyAdmin && !session && !!program?.estimatedLength)
    bottomRightEl = (
      <TimeEstimate>{`${program.estimatedLength} mins`}</TimeEstimate>
    );
  // if admin, estimated length
  if (!!anyAdmin && !!program?.estimatedLength)
    bottomRightEl = (
      <TimeEstimate>{`${program.estimatedLength} mins`}</TimeEstimate>
    );

  return (
    <Link href={link} passHref>
      <Wrapper active={program.active} style={style}>
        <CoverImage color={ownerColor} empty={!program.coverImage}>
          {!!program.coverImage && (
            <Image layout="fill" objectFit="cover" src={program.coverImage} />
          )}
          {!program.coverImage && (
            <BuildIcon
              size={40}
              color={rgba(ownerColor, 0.5)}
              strokeWidth={3}
            />
          )}
        </CoverImage>
        <Top>
          <Title>{program.title}</Title>
        </Top>
        {program.description && (
          <Description>{program.description}</Description>
        )}

        <Bottom>
          {!tagsQuery.isLoading &&
            program.tags?.map((tag, i) => (
              <TagComponent
                key={`tag-${i}`}
                tag={find(tagsQuery.data, { id: tag })}
                style={{ marginRight: 10 }}
              />
            ))}
          {bottomRightEl}
        </Bottom>
      </Wrapper>
    </Link>
  );
};

export default ProgramPreview;
