import styled from "styled-components";
import Link from "next/link";
import { colors } from "@/lib/styles";
import { fadeIn } from "@/lib/animations";
import { ownerPath } from "@/lib/path";
import { useContext } from "react";
import { OwnerContext } from "../App";
import Image from "next/image";
import { MOBILE_WIDTH, TABLET_WIDTH } from "@/lib/constants";
import { Owner } from "@/models/owner";
import { rgba } from "polished";
import useOwnerColor from "@/hooks/useOwnerColor";
import TagComponent from "../universal/Tags/Tag";
import { useQuery } from "react-query";
import { Tag } from "@/models/tag";
import getTags from "@/db/tag/getTags";
import { find } from "lodash";
import useAccess from "@/hooks/useAccess";
import { Folder } from "@/models/folder";
import FolderIcon from "../icons/FolderIcon";

const Wrapper = styled.a<{ active: boolean }>`
  margin: 10px;
  border: 1px solid ${colors.lightGray};
  display: flex;
  flex-direction: column;
  max-width: calc(33.333% - 20px);
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  flex-basis: 33.333%;
  flex-shrink: 0;
  border-radius: 10px;
  text-decoration: none;
  padding-bottom: 10px;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  ${fadeIn}
  &:hover {
    ${(props) =>
      props.active
        ? `
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.08) 0 1px 5px 0;
    `
        : ""}
  }
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    max-width: calc(50% - 20px);
    flex-basis: 50%;
  }
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    max-width: calc(100% - 20px);
    flex-basis: 100%;
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
`;

const Title = styled.h3`
  font-size: 21px;
  font-weight: 500;
  color: ${colors.nearBlack};
  flex: 1;
  padding: 10px 0 5px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 21px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${colors.darkGray};
  width: 100%;
  padding: 0 20px 10px;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px 20px 10px;
  margin-top: auto;
`;

const CoverImage = styled.div<{ color: string; empty?: boolean }>`
  width: 100%;
  height: 180px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${(props) =>
    props.empty ? rgba(props.color, 0.1) : colors.nearWhite};
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    height: 150px;
  }
`;

type Props = {
  folder: Folder;
  owner?: Owner;
  style?: object;
};
export const FolderPreview: React.FC<Props> = ({
  folder,
  style,
  ...otherProps
}) => {
  const access = useAccess(folder);
  const ownerCtx = useContext(OwnerContext);
  const ownerColor = useOwnerColor();
  const owner = ownerCtx || otherProps.owner;

  const tagsQuery = useQuery<Tag[]>(["tags", owner.id], getTags(owner));
  const link = ownerPath(owner, [...folder.slugPaths, folder.slug].join("/"));

  return (
    <Link href={link} passHref>
      <Wrapper active={folder.active} style={style}>
        <CoverImage color={ownerColor} empty={!folder.coverImage}>
          {!!folder.coverImage && (
            <Image layout="fill" objectFit="cover" src={folder.coverImage} />
          )}
          {!folder.coverImage && (
            <FolderIcon
              size={40}
              color={rgba(ownerColor, 0.5)}
              strokeWidth={3}
            />
          )}
        </CoverImage>
        <Top>
          <Title>{folder.title}</Title>
        </Top>
        {folder.description && <Description>{folder.description}</Description>}

        <Bottom>
          {!tagsQuery.isLoading &&
            folder.tags?.map((tag, i) => (
              <TagComponent
                key={`tag-${i}`}
                tag={find(tagsQuery.data, { id: tag })}
                style={{ marginRight: 10 }}
              />
            ))}
        </Bottom>
      </Wrapper>
    </Link>
  );
};

export default FolderPreview;
